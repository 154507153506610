// @flow
export const SITE_URL = 'https://www.latitudefinancial.com.au';

export const TITLE_SUFFIX = 'Latitude Financial Services';

export const GO_MAC_SITE_URL =
  'https://www.latitudefinancial.com.au/credit-cards/gomastercard/';

export const GO_MAC_TITLE_SUFFIX = 'GO Mastercard';

export const PRODUCT_NAMES = Object.freeze({
  '28D': 'Latitude 28° Global Platinum Mastercard',
  GEM: 'Latitude Gem Visa'
});

export const LINE_HEIGHT = Object.freeze({
  STANDARD: {
    SMALL: '24px',
    NORMAL: '26px',
    LARGE: '28px'
  },
  TIGHT: {
    SMALL: '18px',
    NORMAL: '20px',
    LARGE: '24px'
  }
});

export const APP_STORE = Object.freeze({
  APPLE: 'apple',
  GOOGLE: 'google'
});

export const ALIGN = Object.freeze({
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
  JUSTIFY: 'justify'
});

export const BORDER_RADIUS = Object.freeze({
  BR0: '0',
  BRAND: '4px',
  CIRCLE: '50%'
});

export const BREAKPOINT = Object.freeze({
  XS: '380px',
  SM: '576px',
  MD: '768px',
  LG: '992px',
  LG_DOWN: '991px',
  XL: '1200px'
});

export const COLOR = Object.freeze({
  BLUE: '#006AFF',
  BLUE_BABY: '#E8F4FF',
  BLUE_SKY: '#D0E9FF',
  BLUE_LIGHT: '#63B8FF',
  BLUE_LIGHT_BRIGHT: '#CEE6FF',
  BLUE_MID: '#004A95', // #OP-558
  BLUE_DEEP: '#0046AA',
  BLUE_DARK: '#00366c',
  PURPLE: '#ABA7F6',
  PURPLE_LIGHT: '#E5E4FC',
  PURPLE_DARK: '#7A65D4',
  PINK: '#C31B6C',
  PINK_LIGHT: '#FF94CA',
  PINK_BABY: '#FFDEEF',
  PINK_LIGHT_BRIGHT: '#FFDEEF',
  TURQUOISE: '#12DBF5',
  BODY: '#42484d',
  WHITE: '#FFFFFF',
  GREY4: '#F1F2F3',
  GREY5: '#F3F3F3',
  GREY6: '#F8F8F8',
  GREY10: '#E5E5E5',
  GREY12: '#ECECEC',
  GREY16: '#D6D6D6',
  GREY65: '#595959',
  GREY75: '#414141',
  BLACK: '#000000',
  TEXT_ERROR: '#B3000C',
  TRANSPARENT: 'transparent',
  ERROR: 'red'
});

export const CARD_COLOR = Object.freeze({
  BLUE_LIGHTER: '#e8f4ff',
  BLUE_LIGHT: '#d0e9ff',
  BLUE_DARK: '#0046aa'
});

export const FEATURE_COL_COLOR = Object.freeze({
  White: '#ffffff',
  Grey: '#f2f2f2',
  Dark: '#5e47b9'
});

export const RATES_AND_FEES_COLOR = Object.freeze({
  WHITE: '#ffffff',
  GREY: '#f2f2f2',
  BLUE: '#e5f6ff'
});

export const TABBED_CONTENT_COLOR = Object.freeze({
  GREY_LIGHT: '#f2f2f2',
  GREY: '#e6e6e6',
  BLUE_LIGHT: '#e5f6ff',
  BLUE: '#c7ecff',
  PURPLE_LIGHT: '#efebff',
  PURPLE: '#dbd4fc'
});

export const SPAN = Object.freeze({
  '1 Col': 12,
  '2 Cols': 6
});

export const TEXT_TYPE = Object.freeze({
  'Heading 1': 'h1',
  'Heading 2': 'h2',
  'Heading 3': 'h3',
  'Heading 4': 'h4',
  'Heading 5': 'h5'
});

export const COMPONENT_TYPE = Object.freeze({
  ATOM: 'Atoms',
  MOLECULE: 'Molecules',
  ORGANISM: 'Organisms'
});

export const DISPLAY = Object.freeze({
  BLOCK: 'block',
  INLINE: 'inline',
  FLEX: 'flex',
  NONE: 'none'
});

export const FONT_FAMILY = Object.freeze({
  BODY: 'Roboto, Open Sans, sans-serif',
  HEADING: 'Roboto Slab, sans-serif',
  TITLE: 'Montserrat, sans-serif'
});

export const FONT_SIZE = Object.freeze({
  SMALL: '14px',
  NORMAL: '16px',
  LARGE: '18px'
});

export const FONT_WEIGHT = Object.freeze({
  LIGHT: 300,
  NORMAL: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800
});

export const GUTTER = Object.freeze({
  G0: '0px',
  G15: '15px',
  G30: '30px'
});

export const MARGIN = Object.freeze({
  TEXT: '16px',
  MAUTO: 'auto',
  M0: '0px',
  M4: '4px',
  M8: '8px',
  M16: '16px',
  M24: '24px',
  M32: '32px',
  M40: '40px',
  M48: '48px',
  M56: '56px',
  M64: '64px'
});

export const PADDING = Object.freeze({
  P0: '0px',
  P4: '4px',
  P8: '8px',
  P16: '16px',
  P24: '24px',
  P32: '32px',
  P40: '40px',
  P48: '48px',
  P56: '56px',
  P64: '64px',
  P72: '72px'
});

export const OVERFLOW = Object.freeze({
  AUTO: 'auto',
  HIDDEN: 'hidden',
  SCROLL: 'scroll',
  VISIBLE: 'visible'
});

export const POSITION = Object.freeze({
  STATIC: 'static',
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  FIXED: 'fixed'
});

export const ALIGN_CONTENT = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  STRETCH: 'stretch'
});

export const ALIGN_ITEMS = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  STRETCH: 'stretch',
  BASELINE: 'baseline'
});

export const JUSTIFY_CONTENT = Object.freeze({
  FLEX_START: 'flex-start',
  FLEX_END: 'flex-end',
  CENTER: 'center',
  SPACE_BETWEEN: 'space-between',
  SPACE_AROUND: 'space-around',
  SPACE_EVENLY: 'space-evenly'
});

export const FLEX_DIRECTION = Object.freeze({
  ROW: 'row',
  ROW_REVERSE: 'row-reverse',
  COLUMN: 'column',
  COLUMN_REVERSE: 'column-reverse'
});

export const FLEX_WRAP = Object.freeze({
  NOWRAP: 'nowrap',
  WRAP: 'wrap',
  WRAP_REVERSE: 'wrap-reverse'
});

export const FLEX_BASIS = Object.freeze({
  AUTO: 'auto',
  ZERO: '0px' // 'flex-basis: 0' is invalid in IE11, however '0px' is valid
});

export const ICON_SIZE = Object.freeze({
  XS: '16px',
  SM: '32px',
  MD: '48px',
  LG: '64px',
  XL: '96px'
});

export const LAB_ICON_SIZE = Object.freeze({
  SM: '24px'
});

export const ICON_VARIANT = Object.freeze({
  ABOUT_YOU: 'about-you',
  APPLY_ONLINE_BRAND: 'apply-online-branded',
  ARROW_CHEVRON_RIGHT: 'arrow-chevron-right',
  ATTENTION: 'attention',
  BILLS: 'bills',
  BOAT: 'boat',
  CALCULATOR: 'calculator',
  CALL_US_BRAND: 'call-us-branded',
  CAR: 'car',
  CARAVAN: 'caravan',
  CHAT: 'chat',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_UP: 'chevron-up',
  CIRCLE_QUESTION: 'circle-question',
  CIRCLE_TICK: 'circle-tick',
  COCKTAIL: 'cocktail',
  DANCE_STEPS: 'dance-steps',
  DEVICES: 'devices',
  ENVELOPE: 'envelope',
  HAND_DOLLAR: 'hand-dollar',
  FACE_ID: 'face-id',
  IPHONE: 'iphone',
  LIGHT_TRUCK: 'light-truck',
  LIST: 'list',
  LOAN: 'loan',
  MENU: 'menu',
  MOCK_ICON: 'mock-icon',
  MONEY_BUNDLE: 'money-bundle',
  MOTORBIKE: 'motorbike',
  NODE: 'node',
  PHONE: 'phone',
  PLANE: 'plane',
  RELATIONSHIP: 'relationship',
  SHIELD_DOLLAR: 'shield-dollar',
  SHIELD_PADLOCK: 'shield-padlock',
  SLINKY: 'slinky',
  SPEED: 'speed',
  STOPWATCH: 'stopwatch',
  THUMBS_UP: 'thumbs-up',
  TROPHY: 'trophy',
  TICK_RESPONSE: 'response-tick'
});

export const RESOURCE_TYPE = Object.freeze({
  PDF: 'pdf',
  CALCULATOR: 'calculator'
});

export const TEXT_VARIANT = Object.freeze({
  DIV: 'div',
  P: 'p',
  SPAN: 'span'
});

export const LIST_VARIANT = Object.freeze({
  UL: 'ul',
  OL: 'ol'
});

export const HEADING_VARIANT = Object.freeze({
  H1: 'h1',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
  H6: 'h6',
  P: 'p'
});

export const HEADING = Object.freeze({
  hero: {
    FONT_SIZE: {
      NORMAL: '48px',
      LARGE: '104px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '48px',
      LARGE: '120px'
    }
  },
  h1: {
    FONT_SIZE: {
      NORMAL: '48px',
      LARGE: '48px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '56px',
      LARGE: '56px'
    }
  },
  h2: {
    FONT_SIZE: {
      NORMAL: '40px',
      LARGE: '40px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '48px',
      LARGE: '48px'
    }
  },
  h3: {
    FONT_SIZE: {
      NORMAL: '32px',
      LARGE: '40px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '36px',
      LARGE: '48px'
    }
  },
  h4: {
    FONT_SIZE: {
      NORMAL: '24px',
      LARGE: '32px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '28px',
      LARGE: '36px'
    }
  },
  h5: {
    FONT_SIZE: {
      NORMAL: '20px',
      LARGE: '20px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '24px',
      LARGE: '32px'
    }
  },
  h6: {
    FONT_SIZE: {
      NORMAL: '16px',
      LARGE: '16px'
    },
    FONT_WEIGHT: FONT_WEIGHT.SEMI_BOLD,
    FONT_FAMILY: FONT_FAMILY.TITLE,
    LINE_HEIGHT: {
      NORMAL: '22px',
      LARGE: '22px'
    }
  }
});

export const INPUT_PADDING = Object.freeze({
  // SMALL: '14px',
  NORMAL: '0.75em 1em',
  // LARGE: '18px'
  WIDGET: '0.25em 0em'
});

// loan types
export const LOAN_TYPES = Object.freeze({
  PL: 'PL', // personal loan
  CL: 'CL', // car loan
  AL: 'AL' // asset loan
});

export const LOAN_PAY_FREQUENCY = Object.freeze({
  WEEKLY: 'WEEKLY',
  FORTNIGHTLY: 'FORTNIGHTLY',
  MONTHLY: 'MONTHLY'
});

export const LOAN_START_STAGES = Object.freeze({
  INTRO: 'intro',
  FURTHER_INFO: 'further-info',
  LOW_RATE_AVAILABLE: 'low-rate-continue',
  LOW_RATE_UNAVAILABLE: 'confirm',
  SHOW_CREDIT_CARD_OPTIONS: 'show-credit-card',
  UNDER_LOW_RATE_MINIMUM: 'under-lr-min',
  FINISHED: 'finished'
});

export const LOAN_START_REFERRALS = Object.freeze({
  PERSONAL_LOAN: 'personal-loan',
  LOW_RATE: 'low-rate'
});

export const PL_LOAN_PURPOSE = Object.freeze({
  CAR_PURCHASE: 'Car Purchase',
  CAR_REPAIRS: 'Car Repairs',
  DEBT_CONSOLIDATION: 'Debt Consolidation',
  EDUCATIONAL_EXPENSES: 'Educational Expenses',
  HOME_IMPROVEMENTS: 'Home Improvements',
  HOUSEHOLD_FURNISHINGS: 'Household Furnishings',
  MEDICAL_DENTAL: 'Medical / Dental',
  OTHER: 'Other',
  OTHER_VEHICLE_PURCHASE: 'Other Vehicle Purpose',
  SMALL_DEBTS: 'Small Debts',
  TRAVEL: 'Travel'
});

/*
 * Personal Loan constants
 */
export const PL_MINIMUM_AMOUNT = 5000;
export const PL_DEFAULT_WIDGET_AMOUNT = 30000;
export const PL_DEFAULT_PERIOD = '7';
export const PL_SYMPLE_DEFAULT_WIDGET_AMOUNT = 30000;
export const PL_SYMPLE_MINIMUM_AMOUNT = 5000;
export const PL_SYMPLE_DEFAULT_PERIOD = '7';
export const PL_BETTER_START_MINIMUM_AMOUNT = 20000;
export const PL_BROKER_MINIMUM_AMOUNT = 4000;
export const PL_GEM_MINIMUM_AMOUNT = 2000;
export const PL_GEM_DEFAULT_PERIOD = '7';
export const PL_SP_FEE_AMOUNT = 5000;
export const PL_MAXIMUM_AMOUNT = 200000;
export const PL_GEM_MAXIMUM_AMOUNT = 70000;
export const PL_BROKER_MAXIMUM_AMOUNT = 100000;
export const PL_SYMPLE_SECURED_MIN_IR = 9.49;
export const PL_SYMPLE_SECURED_MAX_IR = 29.99;
export const PL_SYMPLE_SECURED_COMPARISON_MIN_IR = 10.93;
export const PL_SYMPLE_SECURED_COMPARISON_MAX_IR = 31.83;
export const PL_SYMPLE_UNSECURED_MIN_IR = 9.49;
export const PL_SYMPLE_UNSECURED_MAX_IR = 29.99;
export const PL_SYMPLE_UNSECURED_COMPARISON_MIN_IR = 10.93;
export const PL_SYMPLE_UNSECURED_COMPARISON_MAX_IR = 31.83;
export const PL_SYMPLE_SETTLEMENT_FEE_CAP = 1990;
export const PL_SECURED_MIN_IR = 7.99;
export const PL_SECURED_MAX_IR = 26.99;
export const PL_SECURED_COMPARISON_MIN_IR = 9.31;
export const PL_SECURED_COMPARISON_MAX_IR = 27.22;
export const PL_UNSECURED_MIN_IR = 7.99;
export const PL_UNSECURED_MAX_IR = 25.99;
export const PL_UNSECURED_COMPARISION_MIN_IR = 9.31;
export const PL_UNSECURED_COMPARISON_MAX_IR = 27.22;
export const PL_MONTHLY_FEE = 13.0;
export const PL_ESTABLISHMENT_FEE = 395;
export const PL_TEL = '13 28 26';
export const PL_GEM_TEL = '0800 422 898';
export const PL_PRODUCT_ID = 'PLAULF-WEB';
export const PL_APPLY_CTA_TRACK_ID = 'calc-apply-now';

/*
 * Personal and Asset Loan Funnel URLS
 */

export const FUNNEL_URL = Object.freeze({
  PL: {
    DEV: 'https://origdev-gecapitalau.cs74.force.com/latitude',
    QA: 'https://origqa-gecapitalau.cs5.force.com/latitude', // This is the necessary value for deployment to pl-softquote-test01 for testing in test01.
    // QA: 'https://preprod-gecapitalau.cs31.force.com/latitude', // This is the necessary value in PREPROD.
    PROD: 'https://www.latitudefinancial.com.au/personal-loan/',
    LANDING_PAGE: '/personal-loan/'
  },
  /**
   * Note: DEV/QA urls for asset loans are placeholders only
   * if correct env urls are found, please update below
   */
  AL: {
    DEV: 'https://dev-emotor.latitudefinancial.com.au/',
    QA: 'https://qa-emotor.latitudefinancial.com.au/',
    PROD: 'https://emotor.latitudefinancial.com.au/'
  },
  SQ: {
    DEV:
      'https://test01.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com',
    QA:
      'https://test01.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com', // This is the value for test01
    // QA: 'https://preprod.staging.quote-latitudefinancial.digitalweb-np.lfscnp.com', // This is the value for preprod
    DEV_LOANS: 'https://sympleloans--dev.sandbox.my.site.com/latitudeUnsecured', // This is the value for SYMPLE DEV
    QA_LOANS: 'https://sympleloans--dev.sandbox.my.site.com/latitudeUnsecured', // This is the value for SYMPLE QA
    PROD_LOANS: 'https://loans.portal.latitudefinancial.com.au',
    PROD_BROKER: 'https://broker.portal.latitudefinancial.com.au',
    PROD: 'https://quote.latitudefinancial.com.au'
  },
  SF_BROKER_DASHBOARD: {
    DEV: 'https://origdev-gecapitalau.cs74.force.com/latitudebrokers/login',
    QA: 'https://origdev-gecapitalau.cs74.force.com/latitudebrokers/login',
    PROD: 'https://latitudebrokers.mypersonalloanapp.com/s/login/'
  }
});

/*
 * Personal Loan landing page URL
 * Absolute path is required as a apply now landing page was added to the application funnel.
 * Path allows for the link component to track Tealium as per business requirements.
 */
const ABSOLUTE_PATH = typeof window !== 'undefined' ? window.origin : '';
export const PL_APPLY_URL = `${ABSOLUTE_PATH}${FUNNEL_URL.PL.LANDING_PAGE}`;

/*
 * Motor Loan
 */

export const ML_APPLY_URL_DEV =
  'https://motorapp-dev2.001.development.odds-np.lfscnp.com/';
export const ML_APPLY_URL_QA =
  'https://motorapp-test2.001.development.odds-np.lfscnp.com/';
export const ML_APPLY_URL = 'https://motorapp.latitudefinancial.com.au/';
export const ML_PRODUCT_ID = 'MLAULF-WEB';

/*
 * Calculator Tealium tracking constants
 */

export const CALCULATOR_TRACKING = Object.freeze({
  CATEGORY: 'calculator',
  ACTION: 'interaction',
  LOCATION: 'Calculate your repayments',
  LABEL: "I'd like to borrow"
});

/*
 * Latitude App
 */

export const LFS_APP = Object.freeze({
  GOOGLE_PLAY: 'Google Play',
  APP_STORE: 'App Store'
});

/*
 * Retrieve Quote from Evari - LFS Insurance
 */

export const EVARI_DEV =
  'https://latitude-test.evari.tech/form/FRM_LATITUDE_RP/';
export const EVARI_PROD =
  'https://insurance.latitudefinancial.com.au/form/FRM_LATITUDE_RP/';

// Soft Quote
export const SOFT_QUOTE_AU_URL = '/au/';
export const SOFT_QUOTE_LOANS_AU_URL = '/s/quote';
export const SOFT_QUOTE_BROKER_URL = '/broker';
export const SOFT_QUOTE_AU_RETRIEVE_URL = '/au/retrieve';
export const SOFT_QUOTE_LOANS_AU_RETRIEVE_URL = '/s/retrieve';
export const SOFT_QUOTE_NZ_URL = '/nz/';
export const SOFT_QUOTE_KB_URL = '/kiwi/';

/**
 * Personal loan home
 */

export const PL_HOME_URL = Object.freeze({
  AU: {
    DEV: '/personal-loan/',
    QA: '/personal-loan/',
    PROD: 'https://www.latitudefinancial.com.au/personal-loan/'
  },
  BROKER: {
    DEV: '/brokers/',
    QA: '/brokers/',
    PROD: 'https://www.latitudefinancial.com.au/brokers/'
  },
  GEM: {
    DEV: '/loans/personal-loans/',
    QA: '/loans/personal-loans/',
    PROD: 'https://www.gemfinance.co.nz/loans/personal-loans/'
  }
});

export const PRODUCT_NAME = 'Latitude GO Mastercard';

export const BUTTON_BG_THEME = Object.freeze({
  '#21a637': 'Application',
  '#0061ee': 'Default',
  '#ffffff': 'Default Inverse'
});

export const BUTTON_BG_LEGACY = Object.freeze({
  '#21a637': 'primary',
  '#0061ee': 'secondary',
});

export const BUTTON_THEME = Object.freeze({
  APPLICATION: 'Application',
  DEFAULT: 'Default',
  DEFAULTINVERSE: 'Default Inverse',
  OUTLINE: 'Outline',
  OUTLINEINVERSE: 'Outline Inverse',
  LINK: 'Link',
  LINKINVERSE: 'Link Inverse'
});

export const CONTENTFUL_COMPONENTS = Object.freeze({
  FIND_AN_OFFER_SECTION: 'componentsOffers',
  FIND_BY_CATEGORY_SECTION: 'componentsOfferCategory',
  HERO_BANNER_CAROUSEL: 'componentsHeroBannerCarousel',
  INTEREST_FREE_OFFERS: 'componentsInterestFreeOffer',
  FEATURE_SLIDER: 'componentsFeatureSlider',
  PROMO_BANNER: 'componentsPromoBanner',
  ALERT: 'componentsAlert',
  FEATURE_COLUMN: 'componentsFeatureCol',
  RATES_AND_FEES: 'componentsRatesAndFees',
  STANDALONE_TEXT: 'standaloneText',
  IN_PAGE_NAV: 'componentsInPageNav',
  SECONDARY_NAV: 'componentsSecondaryNav',
  IMPORTANT_INFO: 'componentsImportantInfo',
  FAQ:'componentsFaq'
});
